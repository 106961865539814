import {del, get, getPaginated, post, put} from '@/services/http.service'
import {SearchResult} from '@/model/common'
import {
    PrestationCriteria,
    PrestationReadData,
    PrestationSaisieSousTraitanceBilanMontantData, PrestationSaisieSousTraitanceVacationMontantData,
    PrestationUpdateData,
    RedevancePrestationCreateData,
    RetrocessionPrestationCreateData,
    RetrocessionPrestationCriteria,
    SousTraitancePrestationCreateData,
    SousTraitancePrestationCriteria,
    SousTraitanceVacationPrestationCreateData,
    SousTraitanceVacationPrestationCriteria,
    TablePrestation,
    TableRedevancePrestation,
    TableRetrocessionPrestation
} from '@/model/prestation'

export function searchTablePaginatedPrestations(criteria: PrestationCriteria): Promise<SearchResult<TablePrestation>> {
    return getPaginated.value('/api/prestation/_search', buildParamsFromPrestationCriteria(criteria))
}

export function findTableRedevancePaginatedPrestationsByUtilisateurIdAndDateBefore(utilisateurId: string, dateBefore: string): Promise<SearchResult<TableRedevancePrestation>> {
    return getPaginated.value('/api/prestation/_findForRedevance', {
        utilisateurId,
        dateBefore
    })
}

export function searchTablePaginatedPrestationsForSousTraitanceByUtilisateurId(utilisateurId: string, criteria: SousTraitancePrestationCriteria): Promise<SearchResult<TablePrestation>> {
    return getPaginated.value('/api/prestation/_searchForSousTraitance', {
        utilisateurId,
        ...buildParamsFromSousTraitancePrestationCriteria(criteria)
    })
}

export function searchTablePaginatedPrestationsForSousTraitanceVacationByUtilisateurId(utilisateurId: string, criteria: SousTraitanceVacationPrestationCriteria): Promise<SearchResult<TablePrestation>> {
    return getPaginated.value('/api/prestation/_searchForSousTraitanceVacation', {
        utilisateurId,
        ...buildParamsFromSousTraitanceVacationPrestationCriteria(criteria)
    })
}

export function searchTablePaginatedPrestationsForRetrocessionByPrestataireId(prestataireId: string, criteria: RetrocessionPrestationCriteria): Promise<SearchResult<TableRetrocessionPrestation>> {
    return getPaginated.value('/api/prestation/_searchForRetrocession', {
        prestataireId,
        ...buildParamsFromRetrocessionPrestationCriteria(criteria)
    })
}

export function getPrestationById(id: string): Promise<PrestationReadData> {
    return get.value(`/api/prestation/${id}`)
}

export function getPrestationSaisieSousTraitanceBilanMontantDataById(id: string): Promise<PrestationSaisieSousTraitanceBilanMontantData> {
    return get.value(`/api/prestation/${id}/sousTraitanceBilanMontantData`)
}

export function getPrestationSaisieSousTraitanceVacationMontantDataById(id: string): Promise<PrestationSaisieSousTraitanceVacationMontantData> {
    return get.value(`/api/prestation/${id}/sousTraitanceVacationMontantData`)
}

export function updatePrestationById(id: string, prestation: PrestationUpdateData) {
    return put.value(`/api/prestation/${id}`, prestation)
}

export function updatePrestationMontantSousTraitanceBilanById(id: string, sousTraitanceBilanMontant: number) {
    return put.value(`/api/prestation/${id}/sousTraitanceBilanMontant`, { sousTraitanceBilanMontant })
}

export function updatePrestationMontantSousTraitanceVacationById(id: string, sousTraitanceVacationMontant: number) {
    return put.value(`/api/prestation/${id}/sousTraitanceVacationMontant`, { sousTraitanceVacationMontant })
}

export function updatePrestationMontantById(id: string, montant: number) {
    return put.value(`/api/prestation/${id}/montant`, { montant })
}

export function createRedevancePrestation(data: RedevancePrestationCreateData) {
    return post.value('/api/prestation/_createRedevance', data)
}

export function createSousTraitancePrestation(data: SousTraitancePrestationCreateData) {
    return post.value('/api/prestation/_createSousTraitance', data)
}

export function createSousTraitanceVacationPrestation(data: SousTraitanceVacationPrestationCreateData) {
    return post.value('/api/prestation/_createSousTraitanceVacation', data)
}

export function deletePrestationById(id: string): Promise<void> {
    return del.value(`/api/prestation/${id}`)
}

export function createRetrocessionPrestation(data: RetrocessionPrestationCreateData) {
    return post.value('/api/prestation/_createRetrocession', data)
}

function buildParamsFromPrestationCriteria(criteria: PrestationCriteria) {
    const params = {} as {[key: string]: string}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if(criteria.filters.recipiendaireDenomination) params.recipiendaireDenomination = criteria.filters.recipiendaireDenomination
    if(criteria.filters.dateBefore) params.dateBefore = criteria.filters.dateBefore
    if(criteria.filters.dateAfter) params.dateAfter = criteria.filters.dateAfter
    if(criteria.filters.description) params.description = criteria.filters.description
    if(criteria.filters.statut) params.statut = criteria.filters.statut
    if(criteria.filters.utilisateurDenomination) params.utilisateurDenomination = criteria.filters.utilisateurDenomination
    if(criteria.filters.prestataireDenomination) params.prestataireDenomination = criteria.filters.prestataireDenomination
    return params
}

function buildParamsFromSousTraitancePrestationCriteria(criteria: SousTraitancePrestationCriteria) {
    const params = {} as {[key: string]: string}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if(criteria.filters.dateBefore) params.dateBefore = criteria.filters.dateBefore
    if(criteria.filters.dateAfter) params.dateAfter = criteria.filters.dateAfter
    return params
}

function buildParamsFromSousTraitanceVacationPrestationCriteria(criteria: SousTraitanceVacationPrestationCriteria) {
    const params = {} as {[key: string]: string}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if(criteria.filters.dateBefore) params.dateBefore = criteria.filters.dateBefore
    if(criteria.filters.dateAfter) params.dateAfter = criteria.filters.dateAfter
    return params
}

function buildParamsFromRetrocessionPrestationCriteria(criteria: RetrocessionPrestationCriteria) {
    const params = {} as {[key: string]: string}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if(criteria.filters.dateBefore) params.dateBefore = criteria.filters.dateBefore
    if(criteria.filters.dateAfter) params.dateAfter = criteria.filters.dateAfter
    return params
}